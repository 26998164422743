import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

const Account = () => {
    const [formData, setFormData] = useState({
        name: '',
        date_of_birth: '',
        town: '',
        country: '',
        email: '',
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch user data
        axios.get('/account')
            .then((response) => setFormData(response.data))
            .catch((error) => console.error('Error fetching account:', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put('/account', formData);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error updating account:', error);
            setMessage('Error updating account.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>Account</Typography>
            {message && <Typography color={message.includes('Error') ? 'error' : 'primary'}>{message}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    value={formData.name}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Date of Birth"
                    name="date_of_birth"
                    type="date"
                    fullWidth
                    margin="normal"
                    value={formData.date_of_birth}
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Town"
                    name="town"
                    fullWidth
                    margin="normal"
                    value={formData.town}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Country"
                    name="country"
                    fullWidth
                    margin="normal"
                    value={formData.country}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled // Email is typically not editable
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Update
                </Button>
            </form>
        </Box>
    );
};

export default Account;