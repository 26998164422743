import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  Toolbar,
  AppBar,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataIcon from "@mui/icons-material/Storage";
import IntegrationIcon from "@mui/icons-material/Sync";
import AccountIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../assets/Logo.png";
import { useTheme } from "@mui/material/styles";

const Menu = ({ isAuthenticated, onLogout, user }) => {
  const [collapsed, setCollapsed] = useState(false); // State to track sidebar collapse
  const [isDrawerOpen, setDrawerOpen] = useState(false); // For temporary drawer on small screens
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  const toggleCollapse = () => setCollapsed(!collapsed);
  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const drawerContent = (
    <>
      {/* Logo and Collapse Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: collapsed ? "center" : "space-between",
          padding: "16px",
        }}
      >
        {!collapsed && (
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "40px", marginRight: "8px", cursor: "pointer" }}
            onClick={() => navigate("/")} // Navigate to root on logo click
          />
        )}
        {!isMobile && (
          <IconButton onClick={toggleCollapse} sx={{ color: "white" }}>
            {collapsed ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        )}
      </Box>

      {/* User Info Section */}
      {!collapsed && isAuthenticated && user && (
        <Box sx={{ padding: "16px", textAlign: "center", borderBottom: "1px solid #333" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#f2c230" }}>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "#ccc" }}>
            {user.email}
          </Typography>
        </Box>
      )}

      {/* Navigation Links */}
      <List>
        {isAuthenticated ? (
          <>
            <ListItem
              button
              component={Link}
              to="/dashboard"
              className={location.pathname === "/dashboard" ? "active-link" : ""}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <DashboardIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Dashboard" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/data"
              className={location.pathname === "/data" ? "active-link" : ""}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <DataIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Data" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/integrations"
              className={location.pathname === "/integrations" ? "active-link" : ""}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <IntegrationIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Integrations" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/account"
              className={location.pathname === "/account" ? "active-link" : ""}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <AccountIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Account" />}
            </ListItem>
            <ListItem button onClick={onLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Logout" />}
            </ListItem>
          </>
        ) : (
          <ListItem button component={Link} to="/">
            <ListItemText primary="Login" />
          </ListItem>
        )}
      </List>
    </>
  );

  return (
    <>
      {isMobile ? (
        <>
          {/* AppBar for Mobile */}
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              {/* Hamburger Menu Icon */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              {/* App Name */}
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                BIKE THINGS
              </Typography>
            </Toolbar>
          </AppBar>

          {/* Drawer for Mobile */}
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            ModalProps={{
              keepMounted: true, // Improves performance on mobile
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: 240, // Full width for the drawer
                backgroundColor: "#484848",
                color: "white",
              },
            }}
          >
            {drawerContent}
          </Drawer>

          {/* Spacer to prevent content from being overlapped by AppBar */}
          <Box sx={{ height: "64px" }} /> {/* Height matches AppBar */}
        </>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: collapsed ? 80 : 240,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: collapsed ? 80 : 240,
              boxSizing: "border-box",
              backgroundColor: "#484848",
              color: "white",
              transition: "width 0.3s",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Menu;