import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Modal,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

// Utility function to convert seconds to hh:mm:ss format
const secondsToHms = (seconds) => {
    if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) return '00:00:00';
    const roundedSeconds = Math.floor(seconds);
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds % 3600) / 60);
    const secs = roundedSeconds % 60;

    const pad = (num) => String(num).padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

const Data = () => {
    const [monthlyActivities, setMonthlyActivities] = useState([]);
    const [calendarActivities, setCalendarActivities] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // Fetch calendar activities for the FullCalendar
    const fetchCalendarActivities = async (year, month) => {
        try {
            const response = await fetch(`/data/monthly/activities?year=${year}&month=${month}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                const formatted = data.map((activity) => ({
                    title: activity.title,
                    start: activity.start,
                    extendedProps: {
                        distance: activity.extendedProps.distance,
                        calories: activity.extendedProps.calories,
                        duration: secondsToHms(activity.extendedProps.duration || 0),
                    },
                }));
                setCalendarActivities(formatted);
            } else {
                console.error('Failed to fetch calendar activities');
            }
        } catch (error) {
            console.error('Error fetching calendar activities:', error);
        }
    };

    // Fetch monthly metrics for DataGrid
    const fetchMonthlyMetrics = async () => {
        try {
            const response = await fetch('/data/monthly/metrics', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setMonthlyActivities(data);
            } else {
                console.error('Failed to fetch monthly metrics');
            }
        } catch (error) {
            console.error('Error fetching monthly metrics:', error);
        }
    };

    // Initial fetch
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Months are 0-based
        fetchMonthlyMetrics();
        fetchCalendarActivities(currentYear, currentMonth);
    }, []);

    // Handle calendar's visible date range change
    const handleDatesSet = (info) => {
        // Use `currentStart` to get the first date of the visible month
        const newYear = info.view.currentStart.getFullYear();
        const newMonth = info.view.currentStart.getMonth() + 1; // Months are 0-based

        // Fetch activities for the new visible month
        fetchCalendarActivities(newYear, newMonth);
    };

    // Handle event click in FullCalendar
    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event);
    };

    return (
        <Container>
            {/* Calendar */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Calendar View
                </Typography>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={calendarActivities}
                    eventClick={handleEventClick}
                    datesSet={handleDatesSet} // Callback when visible date range changes
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: true, // Use 12-hour format with am/pm
                    }}
                />
            </Box>

            {/* Data Grid */}
            <Box sx={{ height: 600 }}>
                <Typography variant="h5" gutterBottom>
                    Monthly Summary
                </Typography>
                <DataGrid
                    rows={monthlyActivities.map((activity, index) => ({
                        id: index,
                        ...activity,
                        formatted_time: secondsToHms(activity.total_time || 0),
                    }))}
                    columns={[
                        { field: 'year', headerName: 'Year', width: 100 },
                        { field: 'month', headerName: 'Month', width: 100 },
                        { field: 'activity_count', headerName: 'Activity Count', width: 150 },
                        { field: 'formatted_time', headerName: 'Total Time (hh:mm:ss)', width: 180 },
                        { field: 'total_calories', headerName: 'Total Calories', width: 150 },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            width: 150,
                            renderCell: (params) => (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => console.log('View activities for:', params.row)}
                                >
                                    View Activities
                                </Button>
                            ),
                        },
                    ]}
                    pageSize={10}
                    rowsPerPageOptions={[10, 20, 50]}
                />
            </Box>

            {/* Modal for Event Details */}
            <Modal open={!!selectedEvent} onClose={() => setSelectedEvent(null)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    {selectedEvent && (
                        <>
                            <Typography variant="h6">{selectedEvent.title}</Typography>
                            <Typography>
                                Distance: {selectedEvent.extendedProps.distance} miles
                            </Typography>
                            <Typography>
                                Calories: {selectedEvent.extendedProps.calories}
                            </Typography>
                            <Typography>
                                Duration: {selectedEvent.extendedProps.duration}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Container>
    );
};

export default Data;