import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#484848", // Main dark color
    },
    secondary: {
      main: "#F2C230", // Accent color
    },
    background: {
      default: "#F2F2F2", // Light gray background
      paper: "#FFFFFF", // White for paper-like components
    },
    text: {
      primary: "#484848", // Default text color for pages
      secondary: "#BF9D36", // Accent color for certain elements
      light: "#FFFFFF", // Text color for menu or dark sections
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;