import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Alert,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSearchParams } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation'; // Reusable LoadingAnimation Component

const IntegrationsForm = () => {
  const [integrations, setIntegrations] = useState([]);
  const [newIntegration, setNewIntegration] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [syncStatus, setSyncStatus] = useState('active');
  const [editIntegration, setEditIntegration] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [searchParams] = useSearchParams();

  // Fetch integrations from the backend
  const fetchIntegrations = async () => {
    setLoading(true);
    try {
      const response = await fetch('/integrations');
      const data = await response.json();
      setIntegrations(data);
    } catch (error) {
      console.error('Error fetching integrations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegrations();

    // Handle Strava integration status
    const status = searchParams.get('status');
    if (status === 'success') {
      setStatusMessage('Strava integration was successful!');
    } else if (status === 'failure') {
      setStatusMessage('Failed to integrate with Strava. Please try again.');
    }
  }, [searchParams]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEditIntegration = (integration) => {
    setEditIntegration(integration);
    setUsername(integration.integration_username || '');
    setPassword(integration.integration_password || '');
    setSyncStatus(integration.sync_status || 'active');
  };

  const handleDeleteIntegration = async (id) => {
    setActionLoading(true);
    try {
      const response = await fetch(`/integrations/${id}`, { method: 'DELETE' });
      if (response.ok) {
        fetchIntegrations();
      } else {
        console.error('Error deleting integration');
      }
    } catch (error) {
      console.error('Error deleting integration:', error);
    } finally {
      setActionLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setActionLoading(true);

    const body = {
      integration_username: username,
      integration_password: password,
      sync_status: syncStatus,
    };

    try {
      let response;
      if (editIntegration) {
        response = await fetch(`/integrations/${editIntegration.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        });
      } else {
        response = await fetch('/integrations', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ integration_name: newIntegration, ...body }),
        });
      }

      if (response.ok) {
        setNewIntegration('');
        setEditIntegration(null);
        setUsername('');
        setPassword('');
        setSyncStatus('active');
        fetchIntegrations();
      } else {
        const error = await response.json();
        console.error('Error creating/updating integration:', error);
      }
    } catch (error) {
      console.error('Error connecting integration:', error);
    } finally {
      setActionLoading(false);
    }
  };

  const handleNewIntegration = (integrationName) => {
    setNewIntegration(integrationName);
    setEditIntegration(null);
    setUsername('');
    setPassword('');
    setSyncStatus('active');
  };

  const handleStravaIntegration = () => {
    const clientId = '139996'; // Your Strava client ID
    const redirectUri = 'http://localhost:3200/integrations/strava/authenticate';
    const scope = 'activity:read_all';
    const authUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}`;

    window.location.href = authUrl;
  };

  const handleCancel = () => {
    setNewIntegration('');
    setEditIntegration(null);
    setUsername('');
    setPassword('');
    setSyncStatus('active');
  };

  const isIntegrationExists = (integrationName) => {
    return integrations.some((integration) => integration.integration_name === integrationName);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Manage Integrations
      </Typography>

      {statusMessage && <Alert severity={statusMessage.includes('successful') ? 'success' : 'error'}>{statusMessage}</Alert>}

      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6">Existing Integrations</Typography>
            {integrations.length > 0 ? (
              integrations.map((integration) => (
                <Card key={integration.id} sx={{ marginBottom: 2 }}>
                  <CardContent>
                    <Typography variant="body1">
                      {integration.integration_name} - {integration.sync_status}
                    </Typography>
                    <Button variant="contained" onClick={() => handleEditIntegration(integration)} sx={{ marginTop: 1 }}>
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteIntegration(integration.id)}
                      disabled={actionLoading}
                      sx={{ marginTop: 1, marginLeft: 1 }}
                    >
                      {actionLoading ? 'Deleting...' : 'Delete'}
                    </Button>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>No integrations found.</Typography>
            )}
          </Box>

          {!editIntegration && !newIntegration && (
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h6" gutterBottom>Select Integration to Connect</Typography>
              {!isIntegrationExists('Garmin') && (
                <Button onClick={() => handleNewIntegration('Garmin')} variant="contained" fullWidth sx={{ marginBottom: 2 }}>
                  Connect Garmin
                </Button>
              )}
              {/* {!isIntegrationExists('MyWellness') && (
                <Button onClick={() => handleNewIntegration('MyWellness')} variant="contained" fullWidth sx={{ marginBottom: 2 }}>
                  Connect MyWellness
                </Button>
              )}
              {!isIntegrationExists('Strava') && (
                <Button onClick={handleStravaIntegration} variant="contained" fullWidth sx={{ marginBottom: 2 }}>
                  Connect Strava
                </Button>
              )} */}
            </Box>
          )}

          {(newIntegration || editIntegration) && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" sx={{ marginTop: 4 }}>
                {editIntegration ? `Edit ${editIntegration.integration_name} Integration` : `Complete ${newIntegration} Integration`}
              </Typography>
              <TextField
                label="Integration Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Box sx={{ position: 'relative', marginBottom: 2 }}>
                <TextField
                  label="Integration Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                  sx={{ position: 'absolute', right: 10, top: 38 }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
              <FormControl fullWidth margin="normal">
                <InputLabel id="sync-status-label">Sync Status</InputLabel>
                <Select labelId="sync-status-label" value={syncStatus} onChange={(e) => setSyncStatus(e.target.value)}>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="paused">Paused</MenuItem>
                  <MenuItem value="error">Error</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button type="submit" variant="contained" fullWidth disabled={actionLoading} sx={{ marginRight: 1 }}>
                  {actionLoading ? 'Saving...' : editIntegration ? 'Save Changes' : `Connect ${newIntegration}`}
                </Button>
                <Button variant="outlined" color="error" fullWidth onClick={handleCancel} sx={{ marginLeft: 1 }}>
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </>
      )}
    </Container>
  );
};

export default IntegrationsForm;