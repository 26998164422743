import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    MenuItem,
    Select,
    Button,
    Grid,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

// Utility function to convert seconds to hh:mm:ss format
const secondsToHms = (seconds) => {
    if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) return '00:00:00';
    const roundedSeconds = Math.floor(seconds);
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds % 3600) / 60);
    const secs = roundedSeconds % 60;

    const pad = (num) => String(num).padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

const Dashboard = () => {
    const [monthlyActivities, setMonthlyActivities] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [monthFilter, setMonthFilter] = useState('');

    // Fetch monthly activities
    const fetchMonthlyActivities = async () => {
        try {
            const response = await fetch('/data/monthly/metrics', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                // console.log("Fetched Data:", data);
                setMonthlyActivities(data);
            } else {
                console.error('Failed to fetch monthly activities');
            }
        } catch (error) {
            console.error('Error fetching monthly activities:', error);
        }
    };

    // Run fetch on component mount
    useEffect(() => {
        fetchMonthlyActivities();
    }, []);

    // Handle filter changes
    const handleYearChange = (event) => {
        setYearFilter(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonthFilter(event.target.value);
    };

    // Filter rows based on selected year and month
    const filteredRows = monthlyActivities.filter((row) => {
        return (
            (yearFilter === '' || row.year === parseInt(yearFilter, 10)) &&
            (monthFilter === '' || row.month === parseInt(monthFilter, 10))
        );
    });

    // Map rows for DataGrid
    const rows = filteredRows.map((activity, index) => ({
        id: index,
        ...activity,
        formatted_time: secondsToHms(activity.total_time || 0), // Pre-format time for clarity
    }));

    //console.log('DataGrid Rows:', rows); // Debugging mapped rows

    // Extract unique years and months for filters
    const years = [...new Set(monthlyActivities.map((activity) => activity.year))];
    const months = [...new Set(monthlyActivities.map((activity) => activity.month))];

    // Handle "View Activities" button click
    const handleViewActivities = (row) => {
        console.log('View activities for:', row);
        // Add your logic to view detailed activities here
    };

    // Define DataGrid columns
    const columns = [
        { field: 'year', headerName: 'Year', width: 100 },
        { field: 'month', headerName: 'Month', width: 100 },
        { field: 'activity_count', headerName: 'Activity Count', width: 150 },
        {
            field: 'formatted_time',
            headerName: 'Total Time (hh:mm:ss)',
            width: 180,
        },
        { field: 'total_calories', headerName: 'Total Calories', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleViewActivities(params.row)}
                >
                    View Activities
                </Button>
            ),
        },
    ];

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography>
            <Typography variant="body1">Welcome to your dashboard!</Typography>

            {/* Filters */}
            <Grid container spacing={2} sx={{ mt: 3, mb: 2 }}>
                <Grid item xs={6} sm={3}>
                    <Typography variant="body1" gutterBottom>
                        Filter by Year:
                    </Typography>
                    <Select
                        value={yearFilter}
                        onChange={handleYearChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="">
                            <em>All Years</em>
                        </MenuItem>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant="body1" gutterBottom>
                        Filter by Month:
                    </Typography>
                    <Select
                        value={monthFilter}
                        onChange={handleMonthChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="">
                            <em>All Months</em>
                        </MenuItem>
                        {months.map((month) => (
                            <MenuItem key={month} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            {/* Data Grid */}
            <Box sx={{ height: 600 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    disableColumnMenu
                />
            </Box>
        </Container>
    );
};

export default Dashboard;