import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import axios from "axios";
import "./App.css";

import Menu from "./components/Menu";
import Login from "./pages/Login";
import IntegrationsForm from "./pages/IntegrationsForm";
import Dashboard from "./pages/Dashboard";
import Data from "./pages/Data";
import Account from "./pages/Account";
import LoadingAnimation from "./components/LoadingAnimation";
import theme from "./theme/theme";

function App() {
  const [loginState, setLoginState] = useState("pending"); // 'pending', 'unauthenticated', 'mfaRequired', 'authenticated'
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // State to store user details

  // Function to clear cookies
  const clearCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  // Validate session
  const validateSession = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("/auth/validate-session", { withCredentials: true });
      if (response.data.isAuthenticated) {
        setLoginState("authenticated");
        setUser(response.data.user); // Store user details  
      } else if (response.data.mfaRequired) {
        setLoginState("mfaRequired");
        setUser(null);
      } else {
        setLoginState("unauthenticated");
        setUser(null);
        clearCookies();
      }
    } catch (error) {
      console.error("Error validating session:", error);
      setLoginState("unauthenticated");
      setUser(null);
      clearCookies();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    validateSession(); // Run session validation on app load
  }, [validateSession]);

  // Handle user logout
  const handleLogout = async () => {
    try {
      const response = await axios.post("/auth/logout", {}, { withCredentials: true });
      if (response.status === 200) {
        setLoginState("unauthenticated");
        setUser(null);
        clearCookies();
      } else {
        console.error("Logout failed:", response.data);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  // Handle successful login
  const handleLogin = (mfaRequired = false, userData = null) => {
    if (mfaRequired) {
      setLoginState("mfaRequired");
    } else {
      setLoginState("authenticated");
      if (userData) setUser(userData); // Update user data from login response
    }
  };

  // Handle MFA completion
  const handleMFAComplete = (userData) => {
    setLoginState("authenticated");
    if (userData) setUser(userData); // Update user data from MFA completion
  };

  // Show loading animation while validating session
  if (loading) {
    return <LoadingAnimation timeout={60000} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: "flex" }}>
          {/* Sidebar Menu */}
          {loginState === "authenticated" && (
            <Menu
              isAuthenticated={loginState === "authenticated"}
              onLogout={handleLogout}
              user={user} // Pass user details to the Menu
            />
          )}

          {/* Main Content Area */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: "24px",
              backgroundColor: "#f5f5f5",
              minHeight: "100vh",
            }}
          >
            <Routes>
              {/* Login Route */}
              <Route
                path="/"
                element={
                  loginState === "unauthenticated" || loginState === "mfaRequired" ? (
                    <Login
                      onLogin={(mfaRequired, userData) =>
                        handleLogin(mfaRequired, userData)
                      }
                      onMFAComplete={handleMFAComplete}
                      loginState={loginState}
                    />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                }
              />

              {/* Protected Routes */}
              <Route
                path="/dashboard"
                element={
                  loginState === "authenticated" ? <Dashboard /> : <Navigate to="/" />
                }
              />
              <Route
                path="/integrations"
                element={
                  loginState === "authenticated" ? <IntegrationsForm /> : <Navigate to="/" />
                }
              />
              <Route
                path="/data"
                element={
                  loginState === "authenticated" ? <Data /> : <Navigate to="/" />
                }
              />
              <Route
                path="/account"
                element={
                  loginState === "authenticated" ? <Account /> : <Navigate to="/" />
                }
              />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;