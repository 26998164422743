import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from '../assets/LoadingCrank.json'; // Adjust the path as needed

const LoadingAnimation = ({ timeout = 60000, onTimeout }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onTimeout) {
        onTimeout();
      }
    }, timeout);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [timeout, onTimeout]);

  return (
    <Player
      autoplay
      loop
      src={loadingAnimation}
      style={{ height: '150px', width: '150px' }}
    />
  );
};

export default LoadingAnimation;